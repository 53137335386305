import axios from "axios";
import useHost from "../../utils/useHost";
import { logout } from "../user";

const api =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    if (action.type !== "apiCall") {
      return next(action);
    }
    next(action);
    const { url, method, data, onSuccess, onError, contentType } =
      action.payload;
    let formData = new FormData();

    if (contentType) {
      for (let key in data) {
        formData.append(key, data[key]);
      }
    }
    try {
      const { URL } = useHost();
      const response = await axios.request({
        baseURL: `${URL}/admin/api`,
        headers: {
          token: getState().user?.currentUser?.token,
          "content-type": contentType || "application/json",
        },
        url,
        method: method,
        data: contentType ? formData : data,
      });

      console.log("api result : ", response.data);
      if (response.data.auth === false) {
        dispatch(logout());
        return;
      }
      if (response.data.errors) {
        // dispatch(alertError(response.data.errors[0].msg));
        dispatch({
          type: onError,
          payload: { ...response.data, postedData: data },
        });
        return;
      }
      if (onSuccess) {
        dispatch({ type: onSuccess, payload: response.data });
      }
    } catch (error) {
      console.log(error);
      if (!onError) {
      } else {
        dispatch({
          type: onError,
          payload: { errors: [{ msg: error.message }], postedData: data },
        });
      }
    }
  };
export default api;
