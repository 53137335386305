import React from "react";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
  Avatar,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { usePWAInstall } from "react-use-pwa-install";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import {
  Menu as MenuIcon,
  AccountCircle,
  ExitToApp,
  Brightness4,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import useHost from "../utils/useHost";
import { changeUser, logout, setThemeMode } from "../store/user";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.default,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    [theme.breakpoints.up("sm")]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      // marginLeft: drawerWidth,
    },
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    borderRight: 0,
  },

  title: {
    flexGrow: 1,

    fontSize: 16,
    fontWeight: "bold",
  },
}));

function AppBarComponent({ handleDrawerToggle }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // const install = usePWAInstall();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { URL } = useHost();
  const { currentUser, theme, list } = useSelector((state) => state.user);

  if (!currentUser) {
    return null;
  }
  return (
    <AppBar
      elevation={0}
      position="fixed"
      className={`${classes.appBar} no-print`}
    >
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          aria-label="Menu"
          onClick={handleDrawerToggle}
          color="primary"
        >
          <MenuIcon />
        </IconButton>
        <Avatar alt={currentUser?.name} src={`${URL}/${currentUser?.avatar}`} />

        <Typography
          color="primary"
          variant="h6"
          className={classes.title}
          noWrap
        >
          {currentUser?.name}
        </Typography>
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="primary"
          >
            <AccountCircle />
          </IconButton>
          {/* {install && (
            <IconButton color="primary" onClick={install}>
              <ImportantDevicesIcon />
            </IconButton>
          )} */}
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            {list.map((item) => (
              <MenuItem
                key={item._id}
                onClick={() => {
                  handleClose();
                  dispatch(changeUser(item));
                }}
              >
                <ListItemIcon>
                  <Avatar
                    alt={item.name}
                    src={item.avatar ? `${URL}/${item.avatar}` : item.name}
                  />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
            <Divider light />
            <MenuItem
              onClick={() => {
                dispatch(logout());
              }}
            >
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary={"Sign out"} />
            </MenuItem>
          </Menu>
          <IconButton
            color="primary"
            onClick={() =>
              dispatch(
                setThemeMode(theme.palette.type === "light" ? "dark" : "light")
              )
            }
          >
            <Brightness4 />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default AppBarComponent;
