import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHomework } from "../store/homework";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  Avatar,
  Box,
  Typography,
  Container,
  Button,
  Grow,
} from "@material-ui/core";
import moment from "moment-timezone";
import useHost from "../utils/useHost";

import HideOnScrollBar from "../components/HideOnScrollBar";
import Loading from "../components/Loading";
import { Alert } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  th: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  noHomework: {
    backgroundColor: theme.palette.error.main,
    opacity: 0.8,
  },
  noBorder: {
    borderWidth: 0,
    width: "100%",
  },
}));

function HomeworkReport({ location, currentUser }) {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const dispatch = useDispatch();
  const { URL } = useHost();
  const { list, loading, fetched, errors } = useSelector(
    (state) => state.homework
  );
  useEffect(() => {
    fetchData();
  }, [selectedDate]);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const fetchData = () => {
    dispatch(getHomework(moment(selectedDate).format("YYYY-MM-DD")));
  };
  if (loading.get) {
    return <Loading />;
  }
  if (errors.get && !loading.get) {
    return (
      <Grow in>
        <Alert
          severity="error"
          action={
            <Button onClick={fetchData} size="small">
              RETRY
            </Button>
          }
        >
          {errors.get}
        </Alert>
      </Grow>
    );
  }
  return (
    <div className="homeworkReportContainer">
      <HideOnScrollBar>
        <div style={{ flexGrow: 1 }}></div>

        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="تاريخ  الواجب"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </HideOnScrollBar>
      <Box height={40} display="flex" flexDirection="row">
        <Box height={40} width={100} bgcolor="primary.main" />
        <Box display="flex" flexDirection="row" ml={1} mr={1}>
          <Avatar
            alt={currentUser?.name}
            src={`${URL}/${currentUser?.avatar}`}
          />
          <Typography color="primary" variant="h6" noWrap>
            {currentUser?.name}
          </Typography>
        </Box>

        <Box height={40} bgcolor="primary.main" flex={1} display="flex" />
      </Box>
      <Box bgcolor="#fff">
        <Container maxWidth="md">
          <Box mt={2}>
            <Typography variant="h5" className="printText" align="center">
              تقرير الواجبات
            </Typography>
            <Typography variant="body2" align="center" className="printText">
              {moment(selectedDate).format("DD/MM/YYYY")}
            </Typography>
          </Box>

          {list.map((item) => (
            <Box mt={2}>
              <table className="table">
                <thead>
                  <tr>
                    <td colspan="5">
                      <Typography align="center" color="primary">
                        {"  "}
                        {` ${item.studyPhase?.name} ${item.studyPhaseBranch?.name}`}{" "}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <th className={classes.th}>المادة</th>
                    <th className={classes.th}>المدرس</th>
                    <th className={classes.th}>الواجب</th>
                    <th className={classes.th}>الارسال</th>
                    <th className={classes.th}>الوقت</th>
                  </tr>
                </thead>
                <tbody>
                  {item.data.map((row) => (
                    <tr>
                      <td className="printText">
                        <Typography
                          className="printText"
                          noWrap
                          variant="caption"
                        >
                          {row.book.name}
                        </Typography>
                      </td>
                      <td className="printText">
                        <Typography
                          className="printText"
                          noWrap
                          variant="caption"
                        >
                          {row.teacher?.name}
                        </Typography>
                      </td>
                      <td>
                        {row.homeworks.length === 0 ? (
                          <Box
                            height={10}
                            width={10}
                            borderRadius={10}
                            className={classes.noHomework}
                          />
                        ) : (
                          row.homeworks.map((row) => (
                            <tr>
                              <td className={"noBorder"}>
                                <Typography
                                  className="printText"
                                  variant="caption"
                                >
                                  {row.text}
                                </Typography>
                              </td>
                            </tr>
                          ))
                        )}
                      </td>
                      <td>
                        <Box className={classes.noHomework} />
                        {row.homeworks.map((row) => (
                          <tr>
                            <td className={"noBorder"}>
                              <Typography
                                noWrap
                                className="printText"
                                variant="caption"
                              >
                                {moment(row.postDate)
                                  .tz("Asia/Baghdad")
                                  .format("DD/MM")}
                              </Typography>
                            </td>
                          </tr>
                        ))}
                      </td>
                      <td>
                        {row.homeworks.map((row) => (
                          <tr>
                            <td className={"noBorder"}>
                              <Typography
                                noWrap
                                className="printText"
                                variant="caption"
                              >
                                {moment(row.postDate)
                                  .tz("Asia/Baghdad")
                                  .format("hh:mm a")}
                              </Typography>
                            </td>
                          </tr>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          ))}
        </Container>
      </Box>

      {/* {list.map((item) => (
        <TableContainer className="homeworkTable" style={{ marginBottom: 10 }}>
          <TableHead>
            <Box
              p={2}
              flex={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {`${item.studyPhase.name} ${item.studyPhaseBranch.name}`}
            </Box>
          </TableHead>
          <Table
            className="homeworkTable"
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>المادة</TableCell>
                <TableCell>الواجب</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {item.data.map((row) => (
                <TableRow key={row.name}>
                  <TableCell>
                    {row.book.name}

                    <Typography
                      noWrap
                      variant="body2"
                      color="textSecondary"
                      fontSize={10}
                    >
                      {row.teacher?.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {row.homeworks.map((row) => (
                      <Box display="flex" flexDirection="column">
                        <Box mb={1}>
                          <Typography variant="body2">{row.text}</Typography>
                        </Box>
                        <Typography variant="body2">
                          {moment(row.postDate).format("YYYY-MM-DD HH:mm a")}
                        </Typography>
                      </Box>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ))} */}
    </div>
  );
}

export default HomeworkReport;
