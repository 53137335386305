import React from "react";

import { AppBar, Slide, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { drawerWidth } from "../utils/vars";

const useStyles = makeStyles((theme) => ({
  hideOnScroll: {
    top: 56,
    paddingLeft: 0,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      paddingLeft: drawerWidth,
    },
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
  },
}));
function HideOnScroll(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide
      className={classes.hideOnScroll}
      appear={false}
      direction="down"
      in={props.disabled ? true : !trigger}
    >
      {children}
    </Slide>
  );
}
const HideOnScrollBar = (props) => {
  const classes = useStyles();
  return (
    <div className="no-print">
      <HideOnScroll {...props} className="no-print">
        <AppBar elevation={0} className={classes.appBar} color="transparent">
          <Toolbar
            disableGutters={props.disableGutters}
            style={{ flexGrow: 1 }}
          >
            {props.children}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </div>
  );
};

export default HideOnScrollBar;
