const validatePhone = (phone) => {
  const numbers = {
    "٠": "0",
    "١": "1",
    "٢": "2",
    "٣": "3",
    "٤": "4",
    "٥": "5",
    "٦": "6",
    "٧": "7",
    "٨": "8",
    "٩": "9",
  };
  let formattedPhone = "";

  if (phone?.length > 0 && !isNaN(parseInt(phone))) {
    formattedPhone = phone
      .split("")
      .map((num) => {
        if (numbers[num]) {
          return numbers[num];
        }
        return num;
      })
      .join("");
    formattedPhone = `+964${formattedPhone.substr(formattedPhone.length - 10)}`;
  }

  return formattedPhone;
};
export default validatePhone;
