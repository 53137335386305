import React from "react";
import { Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import PrivateRoute from "./PrivateRoute";
import AppBar from "../components/AppBar";
import { drawerWidth } from "../utils/vars";

import { Hidden, Drawer, Toolbar } from "@material-ui/core";
import DrawerComponent from "../components/Drawer";
import ExcelPreview from "./ExcelPreview";
import CardsPreview from "./CardsPreview";
import Teachers from "./Teachers";
import HomeworkReport from "./Report.Homework";
import Reports from "./Reports";
import ReportMessages from "./Report.Messages";
const Home = React.lazy(() => import("./Home"));
const Posts = React.lazy(() => import("./Posts"));
const Table = React.lazy(() => import("./Table"));
const StudyPhases = React.lazy(() => import("./StudyPhases"));
const StudyPhaseBranches = React.lazy(() => import("./StudyPhaseBranches"));
const Students = React.lazy(() => import("./Students"));
const Marks = React.lazy(() => import("./Marks"));
const ExamTable = React.lazy(() => import("./Exam.Table"));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: 0,
    paddingRight: 10,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Root() {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <AppBar handleDrawerToggle={handleDrawerToggle} />
      <nav
        className={`${classes.drawer} no-print`}
        aria-label="mailbox folders"
      >
        <Hidden mdUp implementation="css">
          <Drawer
            //  container={container}
            variant="temporary"
            // anchor={theme.direction === "rtl" ? "left" : "right"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <DrawerComponent />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <DrawerComponent />
          </Drawer>
        </Hidden>
      </nav>
      <main className={`${classes.content} print-disable-padding`}>
        <Toolbar className="no-print" />
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/study-phases" component={StudyPhases} />
          <PrivateRoute exact path="/teachers" component={Teachers} />
          <PrivateRoute exact path="/reports" component={Reports} />
          <PrivateRoute
            exact
            path="/reports/homeworks"
            component={HomeworkReport}
          />
          <PrivateRoute
            exact
            path="/reports/messages"
            component={ReportMessages}
          />
          <PrivateRoute
            exact
            path="/study-phases/:studyPhase"
            component={StudyPhaseBranches}
          />
          <PrivateRoute
            exact
            path="/study-phases/:studyPhase/:studyPhaseBranche/students"
            component={Students}
          />
          <PrivateRoute
            exact
            path="/study-phases/:studyPhase/:studyPhaseBranche/table"
            component={Table}
          />
          <PrivateRoute exact path="/posts" component={Posts} />
          <PrivateRoute
            exact
            path="/study-phases/:studyPhase/:studyPhaseBranche/marks"
            component={Marks}
          />
          <PrivateRoute
            exact
            path="/study-phases/:studyPhase/:studyPhaseBranche/exams"
            component={ExamTable}
          />
          <PrivateRoute
            exact
            path="/excelPreview/students"
            component={ExcelPreview}
          />

          <PrivateRoute exact path="/id-cards" component={CardsPreview} />
        </Switch>
      </main>
    </div>
  );
}
