import React from "react";
import ReactDOM from "react-dom";

//import { create } from "jss";
//import rtl from "jss-rtl";

import App from "./routes/App";
import { Provider } from "react-redux";
import store from "./store";
import {
  StylesProvider,
  // jssPreset
} from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import "./i18n";
import "./assets/css/App.css";
//const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <StylesProvider
  // jss={jss}
  >
    <CssBaseline />
    <Provider store={store}>
      <App />
    </Provider>
  </StylesProvider>,
  document.getElementById("root")
);
serviceWorkerRegistration.register();
