import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "studyphasebranches",
  initialState: {
    list: {},
    fetched: {},
    loading: { get: false },
    errors: {},
    lastModified: {},
  },
  reducers: {
    setLoading: (studyphasebranches, action) => {
      studyphasebranches.loading = {
        ...studyphasebranches.loading,
        ...action.payload,
      };
    },
    setErrors: (studyphasebranches, action) => {
      studyphasebranches.errors = {
        ...studyphasebranches.errors,
        ...action.payload,
      };
    },
    getSuccess: (studyphasebranches, action) => {
      const { studyPhase, list } = action.payload;
      for (let i = 0; i < studyPhase.length; i++) {
        let item = studyPhase[i];
        studyphasebranches.loading[item].get = false;
        studyphasebranches.list[item] = list[item];
        studyphasebranches.fetched[item] = true;
      }
    },

    getError: (studyphasebranches, action) => {
      studyphasebranches.loading[
        action.payload.postedData.studyPhase
      ].get = false;

      studyphasebranches.errors[action.payload.postedData.studyPhase].get =
        action.payload.errors[0].msg;
    },
    addSuccess: (studyphasebranches, action) => {
      if (!studyphasebranches.list[action.payload.studyPhase]) {
        studyphasebranches.list[action.payload.studyPhase] = [];
      }
      studyphasebranches.loading[action.payload.studyPhase].add = false;
      studyphasebranches.list[action.payload.studyPhase].push(action.payload);
      studyphasebranches.lastModified[action.payload.studyPhase] = Date.now();
    },

    addError: (studyphasebranches, action) => {
      console.log(action.payload);
      studyphasebranches.loading[
        action.payload.postedData.studyPhase
      ].add = false;

      studyphasebranches.errors[action.payload.postedData.studyPhase].add =
        action.payload.errors[0].msg;
    },
    updateSuccess: (studyphasebranches, action) => {
      studyphasebranches.loading[action.payload.studyPhase].add = false;
      studyphasebranches.list[action.payload.studyPhase] =
        studyphasebranches.list[action.payload.studyPhase].map((item) => {
          if (item._id === action.payload._id) {
            return action.payload;
          }
          return item;
        });
      studyphasebranches.lastModified[action.payload.studyPhase] = Date.now();
    },

    updateError: (studyphasebranches, action) => {
      console.log(action.payload);
      studyphasebranches.loading[
        action.payload.postedData.studyPhase
      ].add = false;

      studyphasebranches.errors[action.payload.postedData.studyPhase].add =
        action.payload.errors[0].msg;
    },
    deleteSuccess: (studyphasebranches, action) => {
      studyphasebranches.loading[action.payload.studyPhase].delete = false;
      studyphasebranches.list[action.payload.studyPhase] =
        studyphasebranches.list[action.payload.studyPhase].filter(
          (item) => !action.payload._ids.includes(item._id)
        );
    },

    deleteError: (studyphasebranches, action) => {
      studyphasebranches.loading[
        action.payload.postedData.studyPhase
      ].delete = false;

      studyphasebranches.errors[action.payload.postedData.studyPhase].delete =
        action.payload.errors[0].msg;
    },
  },
});

export default slice.reducer;
export const { setLoading, setErrors } = slice.actions;
export const getStudyPhaseBraches = (studyPhase) => (dispatch, getState) => {
  let loadingObject = {};
  let errorObject = {};
  for (let item of studyPhase) {
    loadingObject[item] = { get: true };
    errorObject[item] = { get: null };
  }
  dispatch(setLoading(loadingObject));
  dispatch(setErrors(errorObject));

  dispatch({
    type: "apiCall",
    payload: {
      url: `/studyphasebranches/${JSON.stringify(studyPhase)}`,
      method: "get",
      data: { studyPhase: studyPhase },
      onSuccess: "studyphasebranches/getSuccess",
      onError: "studyphasebranches/getError",
    },
  });
};
export const addStudyPhaseBranch =
  ({ studyPhase, name }) =>
  (dispatch, getState) => {
    dispatch(
      setLoading({
        [studyPhase]: { add: true },
      })
    );
    dispatch(setErrors({ [studyPhase]: { add: null } }));
    dispatch({
      type: "apiCall",
      payload: {
        url: `/studyphasebranches`,
        method: "post",
        data: { studyPhase, name },
        onSuccess: "studyphasebranches/addSuccess",
        onError: "studyphasebranches/addError",
      },
    });
  };
export const updateStudyPhaseBranch =
  ({ studyPhase, name, _id }) =>
  (dispatch, getState) => {
    dispatch(
      setLoading({
        [studyPhase]: { add: true },
      })
    );
    dispatch(setErrors({ [studyPhase]: { add: null } }));
    dispatch({
      type: "apiCall",
      payload: {
        url: `/studyphasebranches`,
        method: "put",
        data: { studyPhase, name, _id },
        onSuccess: "studyphasebranches/updateSuccess",
        onError: "studyphasebranches/updateError",
      },
    });
  };
export const deleteStudyPhaseBranch =
  ({ studyPhase, _ids }) =>
  (dispatch, getState) => {
    dispatch(
      setLoading({
        [studyPhase]: { delete: true },
      })
    );
    dispatch(setErrors({ [studyPhase]: { delete: null } }));
    dispatch({
      type: "apiCall",
      payload: {
        url: `/studyphasebranches`,
        method: "delete",
        data: { studyPhase, _ids },
        onSuccess: "studyphasebranches/deleteSuccess",
        onError: "studyphasebranches/deleteError",
      },
    });
  };
