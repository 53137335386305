import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const slice = createSlice({
  name: "exams",
  initialState: {
    list: {},
    fetched: false,
    loading: { get: false },
    errors: [],
    lastModified: {},
  },
  reducers: {
    setLoading: (exams, action) => {
      exams.loading = { ...exams.loading, ...action.payload };
    },
    setErrors: (tables, action) => {
      tables.errors = {
        ...tables.errors,
        ...action.payload,
      };
    },
    getSuccess: (exams, action) => {
      const { studyPhaseBranch, table } = action.payload;
      exams.list[studyPhaseBranch] = table;
      exams.loading[studyPhaseBranch].get = false;
    },

    getError: (exams, action) => {},
    addSuccess: (exams, action) => {},

    addError: (exams, action) => {},
    updateSuccess: (exams, action) => {
      const { studyPhaseBranch, table } = action.payload;
      studyPhaseBranch.forEach((key) => {
        exams.list[key] = table;
        exams.lastModified[key] = Date.now();
      });
      exams.loading.update = false;
    },

    updateError: (exams, action) => {},
    deleteSuccess: (exams, action) => {},

    deleteError: (exams, action) => {},
  },
});

export default slice.reducer;
export const { setLoading, setErrors } = slice.actions;

export const getExamTable = (studyPhaseBranch) => (dispatch) => {
  dispatch(setLoading({ [studyPhaseBranch]: { get: true } }));
  dispatch(setErrors({ [studyPhaseBranch]: { get: null } }));

  dispatch({
    type: "apiCall",
    payload: {
      url: `/exams/${studyPhaseBranch}`,
      method: "get",
      onSuccess: "exams/getSuccess",
      onError: "exams/getError",
    },
  });
};
export const updateExamTable =
  ({ data, studyPhaseBranch }) =>
  (dispatch) => {
    dispatch(setLoading({ update: true }));
    dispatch(setErrors({ update: null }));

    let table = data.map((item) => {
      let book = item.book?._id || item.book;
      let date = moment(item.date).format("YYYY-MM-DD");
      let text = item.text;
      let type = item.type;
      return { book, date, text, type };
    });

    dispatch({
      type: "apiCall",
      payload: {
        url: "/exams",
        data: { table, studyPhaseBranch },
        method: "put",
        onSuccess: "exams/updateSuccess",
        onError: "exams/updateError",
      },
    });
  };
