import {
  Avatar,
  Box,
  Button,
  Container,
  Grow,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMessages } from "../store/messages";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../components/Loading";
import { Alert } from "@material-ui/lab";
import moment from "moment-timezone";
const useStyles = makeStyles((theme) => ({
  th: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  noHomework: {
    backgroundColor: theme.palette.error.main,
    opacity: 0.8,
  },
  noBorder: {
    borderWidth: 0,
    width: "100%",
  },
}));

function ReportMessages({ currentUser }) {
  const { list, loading, fetched, errors } = useSelector(
    (state) => state.messages
  );
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch(getMessages());
  };
  if (loading.get) {
    return <Loading />;
  }
  if (errors.get && !loading.get) {
    return (
      <Grow in>
        <Alert
          severity="error"
          action={
            <Button onClick={fetchData} size="small">
              RETRY
            </Button>
          }
        >
          {errors.get}
        </Alert>
      </Grow>
    );
  }
  return (
    <div>
      <Box height={40} display="flex" flexDirection="row">
        <Box height={40} width={100} bgcolor="primary.main" />
        <Box display="flex" flexDirection="row" ml={1} mr={1}>
          <Avatar
            alt={currentUser?.name}
            src={`${URL}/${currentUser?.avatar}`}
          />
          <Typography color="primary" variant="h6" noWrap>
            {currentUser?.name}
          </Typography>
        </Box>
        <Box height={40} bgcolor="primary.main" flex={1} display="flex" />
      </Box>
      <Box bgcolor="#fff">
        <Container maxWidth="md">
          <Box mt={2}>
            <Typography variant="h5" className="printText" align="center">
              {`تقرير الرسائل ${moment().format("YYYY-MM-DD")}`}
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box bgcolor="#fff">
        <table className="table">
          <thead>
            <tr>
              <th className={classes.th}>الاسم</th>
              <th className={classes.th}>معدل الرد</th>
              <th className={classes.th}>اخر رد</th>
              <th className={classes.th}>الرسائل الغير مقرؤه</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item) => (
              <tr>
                <td className={"noBorder"}>
                  <Typography noWrap className="printText" variant="caption">
                    {item.name}
                  </Typography>
                </td>
                <td className={"noBorder"}>
                  <Typography noWrap className="printText" variant="caption">
                    {item.responseTime}
                  </Typography>
                </td>
                <td className={"noBorder"}>
                  <Typography noWrap className="printText" variant="caption">
                    {item.lastReplyTime}
                  </Typography>
                </td>
                <td className={"noBorder"}>
                  <Typography noWrap className="printText" variant="caption">
                    {item.unreadMessages}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Box
          // className="printonly"
          mt={10}
          ml={2}
          mr={2}
          display={"flex"}
          alignItems={"flex-end"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <Typography noWrap className="printText">
            ياسر محمد حسين
          </Typography>
          <Typography noWrap className="printText">
            م. وحدة تقنية المعلومات
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

export default ReportMessages;
