import {
  Box,
  Card,
  CardActionArea,
  Grid,
  Grow,
  Typography,
} from "@material-ui/core";
import React from "react";
import RoutePath from "../components/RoutePath";
import { makeStyles } from "@material-ui/core/styles";
import { drawerWidth } from "../utils/vars";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import EmailIcon from "@material-ui/icons/Email";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 2,
  },
  paper: {
    height: 140,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      marginRight: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1,
    borderLeft: 0,
  },
  card: {
    borderWidth: 2,
  },
}));
function Reports({ history, location }) {
  const classes = useStyles();
  return (
    <div>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Grow in>
            <Card elevation={0} className={classes.card}>
              <CardActionArea
                onClick={() => {
                  history.push({
                    pathname: `/reports/homeworks`,
                  });
                }}
                className={classes.paper}
              >
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <BusinessCenterIcon />
                  <Typography variant="body1">Homework</Typography>
                </Box>
              </CardActionArea>
            </Card>
          </Grow>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Grow in>
            <Card elevation={0} className={classes.card}>
              <CardActionArea
                onClick={() => {
                  history.push({
                    pathname: `/reports/messages`,
                  });
                }}
                className={classes.paper}
              >
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <EmailIcon />
                  <Typography variant="body1">Messages</Typography>
                </Box>
              </CardActionArea>
            </Card>
          </Grow>
        </Grid>
      </Grid>
      <RoutePath
        pathes={[
          {
            title: "Reports",
            to: "/reports",
          },
        ]}
      />
    </div>
  );
}

export default Reports;
