import React from "react";
import IdCard from "../components/IdCard";

function CardsPreview({ location }) {
  const { data } = location.state;

  return (
    <div className={"cards-paper"}>
      {data.map((item) => {
        return <IdCard item={item} />;
      })}
    </div>
  );
}

export default CardsPreview;
