import React, {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import Select from "@material-ui/core/Select";
import AccordionActions from "@material-ui/core/AccordionActions";
import Paper from "@material-ui/core/Paper";
import {
  Divider,
  Typography,
  IconButton,
  Box,
  useMediaQuery,
  TextField,
  List,
  InputBase,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { Close, Add, Delete, Save } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { getBooks } from "../store/books";
import { getStudyPhaseBraches } from "../store/studyphasebranches";
import { getStudyphases } from "../store/studyphases";
import Alert from "@material-ui/lab/Alert";
import validatePhone from "../utils/validatePhone";
import { addTeacher, updateTeacher } from "../store/teachers";
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 400,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const TeacherCreate = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const { lastModified, loading } = useSelector((state) => state.teachers);
  const { list: booksList, fetched: booksFetched } = useSelector(
    (state) => state.books
  );
  const { list: studyPhaseList, fetched: studyPhaseFetch } = useSelector(
    (state) => state.studyphases
  );
  const { list: studyPhaseBranchList, fetched: studyPhaseBranchFetched } =
    useSelector((state) => state.studyphasebranches);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({});
  const [newBook, setNewBook] = React.useState(" ");
  const [addNewSubject, setAddNewSubject] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);

  useEffect(() => {
    if (didMountRef.current) {
      handleClose();
    } else {
      didMountRef.current = true;
    }
  }, [lastModified]);
  useEffect(() => {
    if (!booksFetched) {
      dispatch(getBooks());
    }
    if (!studyPhaseFetch) {
      dispatch(getStudyphases());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (studyPhaseFetch) {
      const unfetched = studyPhaseList
        .filter((item) => !studyPhaseBranchFetched[item._id])
        .map((item) => item._id);

      dispatch(getStudyPhaseBraches(unfetched));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyPhaseFetch]);
  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    show(data) {
      const teach = [...data.teach].map((teach) => {
        return {
          ...teach,
          studyPhaseBranch: teach.studyPhaseBranch.map((item) => item._id),
        };
      });

      setData({ ...data, teach });

      setOpen(true);
      setNewBook(" ");
      setAddNewSubject(false);
    },
  }));

  const handleChange = (book, event) => {
    //setPersonName(event.target.value);
    setData((prev) => {
      const newTeach = [...prev.teach].map((teach) => {
        if (teach.book._id === book) {
          return {
            ...teach,
            studyPhaseBranch: event.target.value,
          };
        }
        return teach;
      });
      return { ...prev, teach: newTeach };
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleErrorClose = () => {
    setErrorOpen(false);
  };
  const onSaveData = () => {
    if (
      data.name.length === 0 ||
      data.phone.length === 0 ||
      data.teach.length === 0
    ) {
      return setErrorOpen(true);
    }
    let validatedData = {
      ...data,
      phone: validatePhone(data.phone),
      teach: [
        ...data.teach
          .filter((teach) => teach.studyPhaseBranch.length > 0)
          .map((teach) => {
            return { ...teach, book: teach.book._id };
          }),
      ],
    };

    if (validatedData.teach.length === 0 || validatedData.phone.length !== 14) {
      return setErrorOpen(true);
    }
    if (data._id) {
      dispatch(updateTeacher(validatedData));
    } else {
      dispatch(addTeacher(validatedData));
    }
    console.log(validatedData);
  };
  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      fullWidth
      onClose={handleClose}
      disableBackdropClick
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogActions>
        <div style={{ flexGrow: 1 }}>
          <IconButton onClick={handleClose} color="primary">
            <Close />
          </IconButton>
        </div>
        <Button
          startIcon={
            loading.add ? (
              <CircularProgress size={20} color="primary" />
            ) : (
              <Save />
            )
          }
          disabled={loading.add}
          onClick={onSaveData}
          color="primary"
        >
          save
        </Button>
      </DialogActions>
      <DialogContent>
        <Snackbar
          open={errorOpen}
          autoHideDuration={6000}
          onClose={handleErrorClose}
        >
          <Alert onClose={handleClose} severity="warning">
            Please fill all fields
          </Alert>
        </Snackbar>
        <TextField
          margin="dense"
          value={data?.name}
          onChange={(e) => {
            e.persist();

            setData((prev) => {
              return { ...prev, name: e.target.value };
            });
          }}
          label="Name"
          variant="filled"
          fullWidth
        />
        <TextField
          margin="dense"
          value={data?.phone}
          onChange={(e) => {
            e.persist();
            setData((prev) => {
              return { ...prev, phone: e.target.value };
            });
          }}
          label="Phone"
          variant="filled"
          fullWidth
        />
        <Box pb={2} pt={2}>
          <Typography variant="h5">Subjects Teacher Teaches</Typography>
        </Box>
        <Accordion
          onChange={() => setAddNewSubject(true)}
          expanded={addNewSubject}
          variant="outlined"
          style={{ backgroundColor: theme.palette.background.default }}
        >
          <AccordionSummary
            expandIcon={!addNewSubject && <Add />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <Typography className={classes.secondaryHeading}>
              Add New Subject
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Box component={Paper} width="100%" p={1}>
              <FormControl variant="filled" fullWidth>
                <Select
                  value={newBook}
                  onChange={(e) => setNewBook(e.target.value)}
                  input={<InputBase fullWidth />}
                  MenuProps={MenuProps}
                >
                  <MenuItem value=" " disabled>
                    Select Subject
                  </MenuItem>
                  {booksList?.map((book) => (
                    <MenuItem key={book._id} value={book._id}>
                      {book.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <Button size="small" onClick={() => setAddNewSubject(false)}>
              Cancel
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                setData((prev) => {
                  return {
                    ...prev,
                    teach: [
                      {
                        studyPhaseBranch: [],
                        book: booksList.find((book) => book._id === newBook),
                      },
                      ...prev.teach,
                    ],
                  };
                });
                setAddNewSubject(false);
                setNewBook(" ");
              }}
            >
              Add
            </Button>
          </AccordionActions>
        </Accordion>
        {data?.teach?.map((teach) => (
          <List
            style={{
              backgroundColor: theme.palette.background.default,
              marginTop: 10,
            }}
            aria-labelledby="nested-list-subheader"
            subheader={
              <>
                <Box
                  width="100%"
                  display="flex"
                  flex={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box pb={2} pt={2} ml={1} flex={1} display="flex">
                    <Typography variant="h5">{teach.book.name}</Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() =>
                        setData((prev) => {
                          return {
                            ...prev,
                            teach: prev.teach.filter(
                              (item) => item.book._id !== teach.book._id
                            ),
                          };
                        })
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                </Box>
                <Divider />
              </>
            }
            className={classes.root}
          >
            <Box width="100%">
              {studyPhaseList.map((studyPhase) => (
                <Box
                  key={studyPhase._id}
                  display="flex"
                  flexDirection="row"
                  mb={1}
                  p={1}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mr={5}
                  >
                    <Typography noWrap variant="subtitle2">
                      {studyPhase.name}
                    </Typography>
                  </Box>
                  <FormControl fullWidth size="small">
                    <Select
                      multiple
                      value={teach.studyPhaseBranch}
                      onChange={(e) => handleChange(teach.book._id, e)}
                      input={<InputBase fullWidth />}
                      MenuProps={MenuProps}
                    >
                      {studyPhaseBranchList[studyPhase._id]?.map(
                        (studyPhaseBranch) => (
                          <MenuItem
                            key={studyPhaseBranch._id}
                            value={studyPhaseBranch._id}
                          >
                            {studyPhaseBranch.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Box>
              ))}
            </Box>
          </List>
        ))}
      </DialogContent>
    </Dialog>
  );
});

export default TeacherCreate;
