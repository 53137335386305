import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "studyphases",
  initialState: {
    list: [],
    fetched: false,
    loading: { get: false },
    errors: [],
  },
  reducers: {
    setLoading: (studyphases, action) => {
      studyphases.loading = { ...studyphases.loading, ...action.payload };
    },

    getSuccess: (studyphases, action) => {
      studyphases.loading.get = false;
      studyphases.list = action.payload;
      studyphases.fetched = true;
    },

    getError: (studyphases, action) => {
      studyphases.loading.get = false;
      studyphases.errors = action.payload.errors;
    },
    addSuccess: (studyphases, action) => {},

    addError: (studyphases, action) => {},
    updateSuccess: (studyphases, action) => {},

    updateError: (studyphases, action) => {},
    deleteSuccess: (studyphases, action) => {},

    deleteError: (studyphases, action) => {},
  },
});

export default slice.reducer;
export const { setLoading } = slice.actions;
export const getStudyphases = (data) => (dispatch) => {
  dispatch(setLoading({ get: true }));

  dispatch({
    type: "apiCall",
    payload: {
      url: "/studyphases",
      data,
      method: "get",
      onSuccess: "studyphases/getSuccess",
      onError: "studyphases/getError",
    },
  });
};
