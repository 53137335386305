import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "examtypes",
  initialState: {
    list: [],
    examTitles: [],
    examBranches: {},
    fetched: false,
    loading: { get: false },
    errors: [],
  },
  reducers: {
    setLoading: (examtypes, action) => {
      examtypes.loading = { ...examtypes.loading, ...action.payload };
    },

    getSuccess: (examtypes, action) => {
      examtypes.examTitles = action.payload.examTitles;
      examtypes.examBranches = action.payload.examBranches;
      examtypes.fetched = true;
    },

    getError: (examtypes, action) => {},
    addSuccess: (examtypes, action) => {},

    addError: (examtypes, action) => {},
    updateSuccess: (examtypes, action) => {},

    updateError: (examtypes, action) => {},
    deleteSuccess: (examtypes, action) => {},

    deleteError: (examtypes, action) => {},
  },
});

export default slice.reducer;
export const { setLoading } = slice.actions;
export const getExamTypes = (data) => (dispatch) => {
  dispatch(setLoading({ get: true }));

  dispatch({
    type: "apiCall",
    payload: {
      url: "/examtypes",
      data,
      method: "get",
      onSuccess: "examtypes/getSuccess",
      onError: "examtypes/getError",
    },
  });
};
