import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { loginUser } from "../store/user";
import {
  Container,
  Button,
  TextField,
  CircularProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import { alertError } from "../store/alerts";
function Login() {
  let history = useHistory();
  const dispatch = useDispatch();
  const { currentUser, loading, errors } = useSelector((state) => state.user);
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/" } };

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    document.title = "login";
    if (currentUser) {
      document.title = currentUser.name;
      history.replace(from);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);
  useEffect(() => {
    if (errors.length > 0) dispatch(alertError(errors[0]?.msg));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
  const login = (e) => {
    e.preventDefault();
    dispatch(loginUser({ phone, password }));
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {}
      <Container maxWidth="xs">
        <Paper elevation={12} style={{ padding: 50 }}>
          <Typography gutterBottom>Loging</Typography>
          <form onSubmit={login}>
            <TextField
              margin="dense"
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <TextField
              margin="dense"
              variant="outlined"
              required
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              fullWidth
              variant="contained"
              disableElevation
              color="primary"
              type="submit"
              disabled={loading.login}
            >
              {loading.login ? (
                <CircularProgress
                  style={{ height: 20, width: 20, marginRight: 10 }}
                />
              ) : null}{" "}
              Log in
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
}

export default Login;
