import { createSlice } from "@reduxjs/toolkit";
import theme from "../style/theme";
const slice = createSlice({
  name: "user",
  initialState: {
    loading: { login: false },
    currentUser: null,
    list: [],
    theme: theme,
    errors: [],
  },
  reducers: {
    setLoading: (user, action) => {
      user.loading = { ...user.loading, ...action.payload };
    },
    setUser: (user, action) => {
      user.list = action.payload.list;
      user.currentUser = action.payload.currentUser;
      user.theme = action.payload.theme;
    },
    setThemeMode: (user, action) => {
      user.theme = {
        ...user.theme,
        palette: {
          ...user.theme.palette,
          type: action.payload,
        },
      };
      let userCache = JSON.parse(localStorage.getItem("user"));
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...userCache,
          theme: user.theme,
        })
      );
    },
    loginSuccess: (user, action) => {
      user.loading.login = false;
      user.list = action.payload;
      user.currentUser = action.payload[0];
      user.theme = {
        ...user.theme,
        palette: {
          ...user.theme.palette,
          primary: {
            main: action.payload[0].color || theme.palette.primary.main,
          },
        },
      };

      localStorage.setItem(
        "user",
        JSON.stringify({
          currentUser: user.currentUser,
          list: user.list,
          theme: user.theme,
        })
      );
    },
    changeUser: (user, action) => {},
    loginError: (user, action) => {
      user.loading.login = false;
      user.errors = action.payload.errors;
    },
  },
});

export default slice.reducer;
export const { setLoading, loginSuccess, setUser, setThemeMode, changeUser } =
  slice.actions;

export const loginUser = (data) => (dispatch) => {
  dispatch(setLoading({ login: true }));

  dispatch({
    type: "apiCall",
    payload: {
      url: "/user/login",
      data,
      method: "post",
      onSuccess: "user/loginSuccess",
      onError: "user/loginError",
    },
  });
};
export const logout = () => (dispatch) => {
  localStorage.clear();
  dispatch({ type: "user/logoutSuccess" });
};
export const loadUser = (data) => (dispatch) => {
  dispatch(setUser(data));
};
