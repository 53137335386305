import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "tables",
  initialState: {
    list: {},
    fetched: {},
    loading: {},
    errors: [],
    lastModified: {},
  },
  reducers: {
    setLoading: (tables, action) => {
      tables.loading = { ...tables.loading, ...action.payload };
    },
    setErrors: (tables, action) => {
      tables.errors = {
        ...tables.errors,
        ...action.payload,
      };
    },
    getSuccess: (tables, action) => {
      const { studyPhaseBranch, table } = action.payload;
      tables.loading[studyPhaseBranch].get = false;
      tables.fetched[studyPhaseBranch] = true;
      tables.list[studyPhaseBranch] = table;
    },

    getError: (tables, action) => {
      tables.loading[action.payload.postedData.studyPhaseBranch].get = false;
      tables.errors[action.payload.postedData.studyPhaseBranch].get =
        action.payload.errors[0].msg;
    },
    addSuccess: (tables, action) => {},

    addError: (tables, action) => {},
    updateSuccess: (tables, action) => {
      const { studyPhaseBranch, table } = action.payload;
      tables.loading[studyPhaseBranch].update = false;
      tables.list[studyPhaseBranch] = table;
      tables.lastModified[studyPhaseBranch] = Date.now();
    },

    updateError: (tables, action) => {},
    deleteSuccess: (tables, action) => {},

    deleteError: (tables, action) => {},
  },
});

export default slice.reducer;
export const { setLoading, setErrors } = slice.actions;
export const getTable = (studyPhaseBranch) => (dispatch) => {
  dispatch(setLoading({ [studyPhaseBranch]: { get: true } }));
  dispatch(setErrors({ [studyPhaseBranch]: { get: null } }));

  dispatch({
    type: "apiCall",
    payload: {
      url: `/tables/${studyPhaseBranch}`,
      data: { studyPhaseBranch },
      method: "get",
      onSuccess: "tables/getSuccess",
      onError: "tables/getError",
    },
  });
};

export const updateTable =
  ({ studyPhaseBranch, data, studyPhase }) =>
  (dispatch) => {
    dispatch(setLoading({ [studyPhaseBranch]: { update: true } }));
    dispatch(setErrors({ [studyPhaseBranch]: { update: null } }));
    let table = {
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    };

    for (let day in table) {
      let weekday = data[day];
      for (let i = 0; i < weekday.length; i++) {
        let book = weekday[i].book?._id || weekday[i].book;
        table[day].push({ book: book });
      }
    }
    dispatch({
      type: "apiCall",
      payload: {
        url: `/tables`,
        data: { studyPhaseBranch, table, studyPhase },
        method: "put",
        onSuccess: "tables/updateSuccess",
        onError: "tables/updateError",
      },
    });
  };
