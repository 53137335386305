const theme = {
  palette: {
    type: "dark",
    primary: {
      main: "#ceda42",
    },
    secondary: {
      main: "#004b63",
    },
  },
};
export default theme;
