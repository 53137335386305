import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import FolderIcon from "@material-ui/icons/Folder";
import WidgetsIcon from "@material-ui/icons/Widgets";
import BookIcon from "@material-ui/icons/Book";
import DescriptionIcon from "@material-ui/icons/Description";
const useStyles = makeStyles((theme) => ({
  toolbar: { marginTop: 10 },
  listItem: {
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    height: 40,
    alignItems: "center",
  },
  listItemActive: {
    height: 40,
    alignItems: "center",
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    backgroundColor: theme.palette.primary.light,
    "&:hover": { backgroundColor: theme.palette.primary.light },
  },
  listItemText: {
    color: theme.palette.primary.contrastText,
  },
}));
function Drawer() {
  const location = useLocation();
  const classes = useStyles();
  let path = "/" + location.pathname.split("/")[1];

  return (
    <>
      <Toolbar className={classes.toolbar} />

      <List>
        {[
          {
            text: "Classes",
            icon: <FolderIcon />,
            to: "/study-phases",
          },
          {
            text: "Posts",
            icon: <WidgetsIcon />,
            to: "/posts",
          },
          {
            text: "Teachers",
            icon: <SupervisedUserCircleIcon />,
            to: "/teachers",
          },
          {
            text: "Reports",
            icon: <DescriptionIcon />,
            to: "/reports",
          },
        ].map(({ text, icon, to }, index) => (
          <ListItem
            button
            key={text}
            component={Link}
            to={to}
            className={to === path ? classes.listItemActive : classes.listItem}
          >
            <ListItemIcon
              className={to === path ? classes.listItemText : undefined}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
              className={to === path ? classes.listItemText : undefined}
              primary={text}
            />
          </ListItem>
        ))}
      </List>
      <Box flexGrow={1}></Box>
      <Box m={1} display="flex" flexDirection="row" alignItems="flex-end">
        <Box ml={1} mr={0.2}>
          <Typography variant="subtitle2">version </Typography>
        </Box>
        <Typography color="primary">{process.env.REACT_APP_VERSION}</Typography>
      </Box>
    </>
  );
}

export default Drawer;
