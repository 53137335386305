import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "posts",
  initialState: {
    list: [],
    fetched: false,
    loading: { get: false },
    errors: [],
    metadata: { count: 0 },
    lastModified: null,
  },
  reducers: {
    setLoading: (posts, action) => {
      posts.loading = { ...posts.loading, ...action.payload };
    },

    getSuccess: (posts, action) => {
      console.log(action.payload);
      posts.list = [...posts.list, ...action.payload.posts];
      posts.metadata = action.payload.metadata;
      posts.loading.get = false;
      posts.fetched = true;
      posts.lastModified = Date.now();
    },

    getError: (posts, action) => {},
    addSuccess: (posts, action) => {
      posts.list.unshift(action.payload);
      posts.metadata.count = parseInt(posts.metadata.count) + 1;
      posts.loading.add = false;
      posts.lastModified = Date.now();
    },

    addError: (posts, action) => {},
    updateSuccess: (posts, action) => {},

    updateError: (posts, action) => {},
    deleteSuccess: (posts, action) => {
      posts.loading.delete = false;
      posts.list = posts.list.filter((post) => post._id !== action.payload._id);
    },

    deleteError: (posts, action) => {
      posts.loading.delete = false;
    },
  },
});

export default slice.reducer;
export const { setLoading } = slice.actions;
export const getPosts = (page) => (dispatch, getState) => {
  if (!getState().posts.fetched) {
    dispatch(setLoading({ get: true }));
  }

  dispatch({
    type: "apiCall",
    payload: {
      url: `/posts/${page}`,
      data: { page },
      method: "get",
      onSuccess: "posts/getSuccess",
      onError: "posts/getError",
    },
  });
};
export const addPost = (data) => (dispatch, getState) => {
  dispatch(setLoading({ add: true }));
  dispatch({
    type: "apiCall",
    payload: {
      url: `/posts`,
      data: data,
      contentType: "multipart/form-data",
      method: "post",
      onSuccess: "posts/addSuccess",
      onError: "posts/addError",
    },
  });
};

export const deletePost = (_id) => (dispatch, getState) => {
  dispatch(setLoading({ delete: true }));
  dispatch({
    type: "apiCall",
    payload: {
      url: `/posts/${_id}`,
      method: "delete",
      onSuccess: "posts/deleteSuccess",
      onError: "posts/deleteError",
    },
  });
};
