import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "students",
  initialState: {
    list: {},
    fetched: {},
    loading: { get: false },
    errors: {},
    lastModified: {},
    moveActive: false,
    moveData: [],
    searchResults: [],
  },
  reducers: {
    setLoading: (students, action) => {
      students.loading = { ...students.loading, ...action.payload };
    },
    setErrors: (studyphasebranches, action) => {
      studyphasebranches.errors = {
        ...studyphasebranches.errors,
        ...action.payload,
      };
    },
    setMoveActive: (students, action) => {
      const { status, payload } = action.payload;
      students.moveActive = status;
      students.moveData = payload;
    },
    getSuccess: (students, action) => {
      let { studyPhaseBranch, studyPhase, list } = action.payload;
      if (studyPhaseBranch === undefined) {
        studyPhaseBranch = studyPhase;
      }
      students.loading[studyPhaseBranch].get = false;
      students.list[studyPhaseBranch] = list;
      students.fetched[studyPhaseBranch] = true;
    },

    getError: (students, action) => {
      let { postedData } = action.payload;

      students.errors[postedData].get = action.payload.errors[0].msg;
      students.loading[postedData].get = false;
    },
    addSuccess: (students, action) => {
      let { studyPhaseBranch, list } = action.payload;

      students.loading[studyPhaseBranch].add = false;
      students.list[studyPhaseBranch] = [
        ...students.list[studyPhaseBranch],
        ...list,
      ];

      students.lastModified[studyPhaseBranch] = Date.now();
    },

    addError: (students, action) => {
      students.loading[
        action.payload.postedData[0].studyPhaseBranch
      ].add = false;
      students.errors[action.payload.postedData[0].studyPhaseBranch].add =
        action.payload.errors[0].msg;
    },
    updateSuccess: (students, action) => {
      let { studyPhaseBranch, data } = action.payload;
      students.loading[studyPhaseBranch].update = false;
      students.list[studyPhaseBranch] = students.list[studyPhaseBranch].map(
        (item) => {
          if (item._id === data._id) {
            return { ...item, ...data };
          }
          return item;
        }
      );
      students.loading[studyPhaseBranch].update = false;
      students.lastModified[studyPhaseBranch] = Date.now();
    },

    updateError: (students, action) => {
      students.loading[
        action.payload.postedData.studyPhaseBranch._id
      ].update = false;
      students.errors[action.payload.postedData.studyPhaseBranch._id].update =
        action.payload.errors[0].msg;
    },
    deleteSuccess: (students, action) => {
      students.loading[action.payload.studyPhaseBranch].delete = false;
      students.list[action.payload.studyPhaseBranch] = students.list[
        action.payload.studyPhaseBranch
      ].filter((item) => !action.payload._ids.includes(item._id));
    },

    deleteError: (students, action) => {
      students.loading[
        action.payload.postedData.studyPhaseBranch
      ].delete = false;

      students.errors[action.payload.postedData.studyPhaseBranch].delete =
        action.payload.errors[0].msg;
    },
    moveSuccess: (students, action) => {
      const { _ids, newData, studyPhaseBranch } = action.payload;
      for (let studyPhaseBranch in students.list) {
        students.list[studyPhaseBranch] = students.list[
          studyPhaseBranch
        ].filter((student) => !_ids.includes(student._id));
      }
      students.list[studyPhaseBranch] = newData;
      students.lastModified.move = Date.now();
      students.loading.move = false;
      students.moveActive = false;
      students.moveData = [];
    },

    moveError: (students, action) => {
      students.loading.move = false;
      students.errors.move = action.payload.errors[0].msg;
    },
    searchSuccess: (students, action) => {
      students.loading.search = false;

      students.searchResults = action.payload;
    },
  },
});

export default slice.reducer;
export const { setLoading, setErrors, setMoveActive } = slice.actions;
export const getStudents = (studyPhaseBranch) => (dispatch) => {
  dispatch(setLoading({ [studyPhaseBranch]: { get: true } }));
  dispatch(setErrors({ [studyPhaseBranch]: { get: null } }));

  dispatch({
    type: "apiCall",
    payload: {
      url: `/students/${studyPhaseBranch}`,
      data: studyPhaseBranch,
      method: "get",
      onSuccess: "students/getSuccess",
      onError: "students/getError",
    },
  });
};
export const getStudentsStudyPhase = (studyPhase) => (dispatch) => {
  dispatch(setLoading({ [studyPhase]: { get: true } }));
  dispatch(setErrors({ [studyPhase]: { get: null } }));

  dispatch({
    type: "apiCall",
    payload: {
      url: `/students/studyPhase/${studyPhase}`,
      data: studyPhase,
      method: "get",
      onSuccess: "students/getSuccess",
      onError: "students/getError",
    },
  });
};
export const addStudent = (data) => (dispatch, getState) => {
  dispatch(
    setLoading({
      [data.studyPhaseBranch]: { add: true },
    })
  );
  dispatch(setErrors({ [data.studyPhaseBranch]: { add: null } }));
  dispatch({
    type: "apiCall",
    payload: {
      url: `/students`,
      method: "post",
      data: data,
      onSuccess: "students/addSuccess",
      onError: "students/addError",
    },
  });
};
export const updateStudent = (data) => (dispatch, getState) => {
  dispatch(
    setLoading({
      [data.studyPhaseBranch]: { update: true },
    })
  );
  dispatch(setErrors({ [data.studyPhaseBranch]: { update: null } }));
  dispatch({
    type: "apiCall",
    payload: {
      url: `/students`,
      method: "put",
      data: data,
      onSuccess: "students/updateSuccess",
      onError: "students/updateError",
    },
  });
};
export const moveStudents = (data) => (dispatch, getState) => {
  dispatch(setLoading({ move: true }));
  dispatch(setErrors({ move: null }));
  dispatch({
    type: "apiCall",
    payload: {
      url: `/students/move`,
      method: "put",
      data: data,
      onSuccess: "students/moveSuccess",
      onError: "students/moveError",
    },
  });
};
export const deleteStudent =
  ({ studyPhaseBranch, _ids }) =>
  (dispatch, getState) => {
    dispatch(
      setLoading({
        [studyPhaseBranch]: { delete: true },
      })
    );
    dispatch(setErrors({ [studyPhaseBranch]: { delete: null } }));
    dispatch({
      type: "apiCall",
      payload: {
        url: `/students`,
        method: "delete",
        data: { studyPhaseBranch, _ids },
        onSuccess: "students/deleteSuccess",
        onError: "students/deleteError",
      },
    });
  };
export const searchStudent = (data) => (dispatch, getState) => {
  dispatch(setLoading({ search: true }));
  dispatch(setErrors({ search: null }));
  dispatch({
    type: "apiCall",
    payload: {
      url: `/students/search/${data}`,
      method: "get",
      onSuccess: "students/searchSuccess",
      onError: "students/searchError",
    },
  });
};
