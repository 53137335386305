import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import theme from "../style/theme";

import user from "./user";
import alerts from "./alerts";
import studyphases from "./studyphases";
import studyphasebranches from "./studyphasebranches";
import students from "./students";
import details from "./details";
import books from "./books";
import tables from "./tables";
import posts from "./posts";
import marks from "./marks";
import examtypes from "./examtypes";
import teachers from "./teachers";
import homework from "./homework.js";
import exams from "./exams.js";
import messages from "./messages.js";

import api from "./middlewares/api";
import chatApi from "./middlewares/chatApi";

const combinedReducer = combineReducers({
  user,
  studyphases,
  alerts,
  studyphasebranches,
  students,
  details,
  books,
  tables,
  posts,
  marks,
  examtypes,
  teachers,
  homework,
  exams,
  messages,
});

const rootReducer = (state, action) => {
  if (action.type === "user/logoutSuccess") {
    state = undefined;
  }
  if (action.type === "user/changeUser") {
    let user = { ...state.user };
    user.currentUser = action.payload;
    document.title = action.payload.name;
    user.theme = {
      ...user.theme,
      palette: {
        ...user.theme.palette,
        primary: { main: action.payload.color || theme.palette.primary.main },
      },
    };
    let userCache = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem(
      "user",
      JSON.stringify({
        ...userCache,
        theme: user.theme,
        currentUser: action.payload,
      })
    );
    state = { user };
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["apiCall"],
      },
    }),
    api,
    chatApi,
  ],
});
