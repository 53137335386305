import React from "react";
import { useSelector } from "react-redux";
import Grow from "@material-ui/core/Grow";
import Alert from "@material-ui/lab/Alert";
import { Box } from "@material-ui/core";

function Alerts() {
  const { list } = useSelector((state) => state.alerts);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 150,
        right: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {list.map((item) => (
        <Grow in key={item._id} unmountOnExit mountOnEnter>
          <Box m={1}>
            <Alert severity="error">{item.msg}</Alert>
          </Box>
        </Grow>
      ))}
    </div>
  );
}

export default Alerts;
