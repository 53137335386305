import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "teachers",
  initialState: {
    list: [],
    fetched: false,
    loading: { get: false },
    errors: [],
    lastModified: null,
  },
  reducers: {
    setLoading: (teachers, action) => {
      teachers.loading = { ...teachers.loading, ...action.payload };
    },
    setErrors: (teachers, action) => {
      teachers.errors = {
        ...teachers.errors,
        ...action.payload,
      };
    },
    getSuccess: (teachers, action) => {
      teachers.list = action.payload;
      teachers.fetched = true;
      teachers.loading.get = false;
    },

    getError: (teachers, action) => {},
    addSuccess: (teachers, action) => {
      teachers.list.push(action.payload);
      teachers.lastModified = Date.now();
      teachers.loading.add = false;
    },

    addError: (teachers, action) => {},
    updateSuccess: (teachers, action) => {
      teachers.list = teachers.list.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload;
        }
        return item;
      });
      teachers.lastModified = Date.now();
      teachers.loading.add = false;
    },

    updateError: (teachers, action) => {},
    deleteSuccess: (teachers, action) => {
      teachers.loading.delete = false;
      teachers.list = teachers.list.filter(
        (item) => !action.payload._ids.includes(item._id)
      );
    },

    deleteError: (teachers, action) => {
      teachers.loading.delete = false;

      teachers.errors.delete = action.payload.errors[0].msg;
    },
  },
});

export default slice.reducer;
export const { setLoading, setErrors } = slice.actions;
export const getTeachers = (data) => (dispatch) => {
  dispatch(setLoading({ get: true }));
  dispatch(setErrors({ get: null }));

  dispatch({
    type: "apiCall",
    payload: {
      url: "/teachers",
      data,
      method: "get",
      onSuccess: "teachers/getSuccess",
      onError: "teachers/getError",
    },
  });
};
export const addTeacher = (data) => (dispatch) => {
  dispatch(setLoading({ add: true }));
  dispatch(setErrors({ add: null }));

  dispatch({
    type: "apiCall",
    payload: {
      url: "/teachers",
      data,
      method: "post",
      onSuccess: "teachers/addSuccess",
      onError: "teachers/addError",
    },
  });
};
export const updateTeacher = (data) => (dispatch) => {
  dispatch(setLoading({ add: true }));
  dispatch(setErrors({ add: null }));

  dispatch({
    type: "apiCall",
    payload: {
      url: "/teachers",
      data,
      method: "put",
      onSuccess: "teachers/updateSuccess",
      onError: "teachers/updateError",
    },
  });
};
export const deleteTeachers = (data) => (dispatch) => {
  dispatch(setLoading({ delete: true }));
  dispatch(setErrors({ delete: null }));

  dispatch({
    type: "apiCall",
    payload: {
      url: "/teachers",
      data,
      method: "delete",
      onSuccess: "teachers/deleteSuccess",
      onError: "teachers/deleteError",
    },
  });
};
