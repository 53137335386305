import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "messages",
  initialState: {
    list: [],
    fetched: false,
    loading: { get: false },
    errors: {},
  },
  reducers: {
    setLoading: (messages, action) => {
      messages.loading = { ...messages.loading, ...action.payload };
    },
    setErrors: (messages, action) => {
      messages.errors = {
        ...messages.errors,
        ...action.payload,
      };
    },
    getSuccess: (messages, action) => {
      messages.list = action.payload;
      messages.loading.get = false;
      messages.fetched = true;
    },

    getError: (messages, action) => {
      messages.errors.get = action.payload.errors[0].msg;
      messages.loading.get = false;
    },
    addSuccess: (messages, action) => {},

    addError: (messages, action) => {},
    updateSuccess: (messages, action) => {},

    updateError: (messages, action) => {},
    deleteSuccess: (messages, action) => {},

    deleteError: (messages, action) => {},
  },
});

export default slice.reducer;
export const { setLoading, setErrors } = slice.actions;
export const getMessages = (data) => (dispatch) => {
  dispatch(setLoading({ get: true }));
  dispatch(setErrors({ get: null }));
  dispatch({
    type: "chatApi",
    payload: {
      url: `/report`,
      data,
      method: "get",
      onSuccess: "messages/getSuccess",
      onError: "messages/getError",
    },
  });
};
