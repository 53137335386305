import React, { useRef, useEffect } from "react";
import * as QRCode from "easyqrcodejs";
import { Box } from "@material-ui/core";
import logo from "../assets/images/qr-bg.png";
import { useStore } from "react-redux";
import useHost from "../utils/useHost";
function IdCard({ item }) {
  const store = useStore();
  const { URL } = useHost();
  const qrcode = useRef();
  const options = {
    text: item._id,
    logo: logo,
    dotScale: 0.5,
    height: 180,
    width: 180,
    autoColor: true,
  };

  useEffect(() => {
    new QRCode(qrcode.current, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box className={"idCard"} bgcolor="white">
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="row"
        height={40}
        width="100%"
      >
        <p
          style={{
            color: store.getState().user.currentUser.color,
            fontSize: 12,
            textAlign: "center",
          }}
        >
          {store.getState().user.currentUser.name}
        </p>
        <img
          width={40}
          alt={"logo"}
          src={`${URL}/${store.getState().user.currentUser.avatar}`}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginLeft={10}
        mr={10}
      >
        <div ref={qrcode}></div>
      </Box>
      <p
        style={{
          color: "#000",
          fontSize: 12,
          textAlign: "center",
          padding: 0,
          margin: 0,
          marginTop: 10,
        }}
      >
        {item.name}
      </p>
      {item.role === "student" ? (
        <p style={{ color: "#000", fontSize: 10, textAlign: "center" }}>
          {`${item?.studyPhase?.name} ${item?.studyPhaseBranch?.name}`}
        </p>
      ) : item.role === "teacher" ? (
        <p style={{ color: "#000", fontSize: 10, textAlign: "center" }}>
          {item.teach?.map((item) => item.book.name).toString()}
        </p>
      ) : null}

      <p
        style={{
          color: "#000",
          fontSize: 12,
          textAlign: "center",
          padding: 0,
          margin: 0,
        }}
      >
        {item.phone}
      </p>
    </Box>
  );
}

export default IdCard;
