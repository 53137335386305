import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useHistory } from "react-router-dom";
import Loading from "../components/Loading";

function ProtectedRoute({ component: Component, ...rest }) {
  const history = useHistory();
  const { currentUser } = useSelector((state) => state.user);
  useEffect(() => {
    if (currentUser) {
      history.replace("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);
  return currentUser ? (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<Loading />}>
          <Component {...props} currentUser={currentUser} />
        </Suspense>
      )}
    />
  ) : (
    <Redirect
      to={{
        pathname: "/init",
        state: { path: rest.location.pathname + rest.location.search },
      }}
      {...rest}
    />
  );
}

export default ProtectedRoute;
