import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Grow,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import HideOnScrollBar from "../components/HideOnScrollBar";
import Loading from "../components/Loading";
import { deleteTeachers, getTeachers } from "../store/teachers";

import CancelIcon from "@material-ui/icons/Cancel";

import {
  CloudDownload,
  DeleteForever,
  Edit,
  RecentActors,
  SelectAllTwoTone,
} from "@material-ui/icons";
import Add from "@material-ui/icons/Add";
import PanoramaFishEyeIcon from "@material-ui/icons/PanoramaFishEye";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import TeacherPreview from "../components/Teacher.Preview";
import useHost from "../utils/useHost";
import AvatarPlaceholder from "../assets/images/avatar_placeholder.png";
import TeacherCreate from "../components/Teacher.Create";
import RoutePath from "../components/RoutePath";
import ConfirmDelete from "../components/ConfirmDelete";
import DescriptionIcon from "@material-ui/icons/Description";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  media: {
    height: 140,
  },
}));
function Teachers({ history, location }) {
  const dispatch = useDispatch();
  const { URL } = useHost();
  const { list, fetched, loading, errors } = useSelector(
    (state) => state.teachers
  );

  const [selectable, setSelectable] = useState(false);
  const [selected, setSelected] = useState({});
  const confirmDelete = useRef();
  const teacherCreate = useRef();
  const teacherPreview = useRef();
  const classes = useStyles();
  const [mouse, setMouse] = useState({
    mouseX: null,
    mouseY: null,
  });
  const [choosed, setChoosed] = useState(null);
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = () => {
    let _ids = Object.keys(selected);
    dispatch(deleteTeachers({ _ids }));
  };
  const fetchData = () => {
    if (!fetched) {
      dispatch(getTeachers());
    }
  };
  const handleClick = (event) => {
    event.preventDefault();
    setMouse({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = () => {
    setMouse({
      mouseX: null,
      mouseY: null,
    });
  };
  if (loading.get) {
    return <Loading />;
  }
  if (!fetched && errors.get && !loading.get) {
    return (
      <Grow in>
        <Alert
          severity="error"
          action={
            <Button onClick={fetchData} size="small">
              RETRY
            </Button>
          }
        >
          {errors.get}
        </Alert>
      </Grow>
    );
  }
  return (
    <>
      <HideOnScrollBar>
        <Button
          onClick={() => {
            setSelectable(!selectable);
            setSelected({});
          }}
          startIcon={selectable ? <CancelIcon /> : <SelectAllTwoTone />}
        >
          {selectable ? "Cancel" : "Select"}
        </Button>
        <Grow in={Object.keys(selected).length > 0}>
          <Button
            onClick={() => {
              confirmDelete.current.show();
            }}
            startIcon={<DeleteForever color="error" />}
          >
            Delete
          </Button>
        </Grow>

        <div style={{ flexGrow: 1 }}></div>

        <Button
          onClick={() => {
            let payload = [];
            if (selectable) {
              const _ids = Object.keys(selected);
              payload = list.filter((item) => _ids.includes(item._id));
            } else {
              payload = list;
            }
            history.push({
              pathname: "/excelPreview/students",
              state: {
                data: payload,
                filename: "teachers",
              },
            });
          }}
          startIcon={<CloudDownload />}
        >
          Excel
        </Button>

        <Button
          onClick={() => {
            let payload = [];
            if (selectable) {
              const _ids = Object.keys(selected);
              payload = list.filter((item) => _ids.includes(item._id));
            } else {
              payload = list;
            }
            history.push({
              pathname: `/id-cards`,
              state: {
                data: payload,
                filename: "teachers",
              },
            });
          }}
          startIcon={<RecentActors />}
        >
          ID Cards
        </Button>

        <Grow unmountOnExit mountOnEnter in={!selectable}>
          <Button
            onClick={() =>
              teacherCreate.current.show({ name: "", phone: "", teach: [] })
            }
            startIcon={<Add />}
          >
            Create
          </Button>
        </Grow>
      </HideOnScrollBar>

      <Grid container className={classes.root} spacing={2}>
        {list?.map((item) => (
          <Grid
            onContextMenu={(e) => {
              handleClick(e);
              setChoosed(item);
            }}
            key={item._id}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
          >
            <Grow in>
              <Card elevation={0}>
                {selectable ? (
                  <CardHeader
                    style={{ position: "absolute", zIndex: 100 }}
                    action={
                      selected[item._id] ? (
                        <CheckCircleIcon color="primary" />
                      ) : (
                        <PanoramaFishEyeIcon />
                      )
                    }
                  />
                ) : null}
                <CardActionArea
                  onClick={() => {
                    if (selectable) {
                      setSelected((prev) => {
                        if (selected[item._id]) {
                          let temp = { ...prev };

                          delete temp[item._id];
                          return temp;
                        }
                        return { ...prev, [item._id]: true };
                      });
                      return;
                    }
                    teacherPreview.current.show(item);
                  }}
                  className={classes.paper}
                >
                  <CardMedia
                    className={classes.media}
                    image={
                      item.avatar ? `${URL}/${item.avatar}` : AvatarPlaceholder
                    }
                  />
                  <CardContent>
                    <Typography>{item.name}</Typography>
                    <Typography color="textSecondary" variant="subtitle2">
                      {item.teach?.map((item) => item.book.name).toString()}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grow>
          </Grid>
        ))}
      </Grid>
      <Menu
        onContextMenu={(event) => {
          event.preventDefault();
          handleClose();
        }}
        keepMounted
        open={mouse.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mouse.mouseY !== null && mouse.mouseX !== null
            ? { top: mouse.mouseY, left: mouse.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            handleClose();
            teacherCreate.current.show(choosed);
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit Teacher</ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmDelete onConfirm={onDelete} ref={confirmDelete} />
      <TeacherPreview ref={teacherPreview} />
      <TeacherCreate ref={teacherCreate} />
      <RoutePath pathes={[{ title: "Teachers" }]} />
    </>
  );
}

export default Teachers;
