import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "homework",
  initialState: {
    list: [],
    fetched: false,
    loading: { get: false },
    errors: {},
  },
  reducers: {
    setLoading: (homework, action) => {
      homework.loading = { ...homework.loading, ...action.payload };
    },
    setErrors: (homework, action) => {
      homework.errors = {
        ...homework.errors,
        ...action.payload,
      };
    },
    getSuccess: (homework, action) => {
      homework.list = action.payload;
      homework.loading.get = false;
      homework.fetched = true;
    },

    getError: (homework, action) => {
      homework.errors.get = action.payload.errors[0].msg;
      homework.loading.get = false;
    },
    addSuccess: (homework, action) => {},

    addError: (homework, action) => {},
    updateSuccess: (homework, action) => {},

    updateError: (homework, action) => {},
    deleteSuccess: (homework, action) => {},

    deleteError: (homework, action) => {},
  },
});

export default slice.reducer;
export const { setLoading, setErrors } = slice.actions;
export const getHomework = (data) => (dispatch) => {
  dispatch(setLoading({ get: true }));
  dispatch(setErrors({ get: null }));
  dispatch({
    type: "apiCall",
    payload: {
      url: `/homework/${data}`,
      data,
      method: "get",
      onSuccess: "homework/getSuccess",
      onError: "homework/getError",
    },
  });
};
