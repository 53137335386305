import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "marks",
  initialState: {
    list: {},
    fetched: {},
    loading: { get: false },
    errors: [],
    lastModified: null,
  },
  reducers: {
    setLoading: (marks, action) => {
      marks.loading = { ...marks.loading, ...action.payload };
    },

    getSuccess: (marks, action) => {
      const { examTitle, examBranch, studyPhaseBranch, data } = action.payload;
      if (!marks.list[studyPhaseBranch]) {
        marks.list[studyPhaseBranch] = {};
        marks.fetched[studyPhaseBranch] = {};
      }
      if (!marks.list[studyPhaseBranch][examTitle]) {
        marks.list[studyPhaseBranch][examTitle] = {};
        marks.fetched[studyPhaseBranch][examTitle] = {};
      }

      marks.list[studyPhaseBranch][examTitle][examBranch] =
        data[studyPhaseBranch][examTitle][examBranch];
      marks.fetched[studyPhaseBranch][examTitle][examBranch] = true;
      marks.loading[studyPhaseBranch][examTitle][examBranch].get = false;
    },

    getError: (marks, action) => {},
    addSuccess: (marks, action) => {
      marks.loading.add = false;
      marks.lastModified = Date.now();
    },

    addError: (marks, action) => {
      marks.loading.add = false;
    },
    updateMarkValue: (marks, action) => {
      const { examTitle, examBranch, studyPhaseBranch, student, book, value } =
        action.payload;
      console.log(
        JSON.stringify(
          marks.list[studyPhaseBranch][examTitle][examBranch][student]
        )
      );
      if (!marks.list[studyPhaseBranch][examTitle][examBranch][student]) {
        marks.list[studyPhaseBranch][examTitle][examBranch][student] = {};
      }

      marks.list[studyPhaseBranch][examTitle][examBranch][student][book] =
        value;
    },
    setMultiMarkValue: (marks, action) => {
      const { examTitle, examBranch, studyPhaseBranch, students, book, value } =
        action.payload;

      for (let i = 0; i < students.length; i++) {
        let student = students[i];
        if (!marks.list[studyPhaseBranch][examTitle][examBranch][student]) {
          marks.list[studyPhaseBranch][examTitle][examBranch][student] = {};
        }
        marks.list[studyPhaseBranch][examTitle][examBranch][student][book] =
          value;
      }
    },
    deleteMultiMarkValue: (marks, action) => {
      const { examTitle, examBranch, studyPhaseBranch, students, book, value } =
        action.payload;

      for (let i = 0; i < students.length; i++) {
        let student = students[i];
        if (!marks.list[studyPhaseBranch][examTitle][examBranch][student]) {
          marks.list[studyPhaseBranch][examTitle][examBranch][student] = {};
        }
        delete marks.list[studyPhaseBranch][examTitle][examBranch][student][
          book
        ];
      }
    },
    updateSuccess: (marks, action) => {},

    updateError: (marks, action) => {},
    deleteSuccess: (marks, action) => {},

    deleteError: (marks, action) => {},
  },
});

export default slice.reducer;
export const {
  setLoading,
  updateMarkValue,
  setMultiMarkValue,
  deleteMultiMarkValue,
} = slice.actions;
export const getMarks = (data) => (dispatch) => {
  const { studyPhaseBranch, examTitle, examBranch } = data;
  let loading = {
    [studyPhaseBranch]: {
      [examTitle]: {
        [examBranch]: {
          get: true,
        },
      },
    },
  };

  dispatch(setLoading(loading));

  dispatch({
    type: "apiCall",
    payload: {
      url: `/marks/${examTitle}/${examBranch}/${studyPhaseBranch}`,
      data,
      method: "get",
      onSuccess: "marks/getSuccess",
      onError: "marks/getError",
    },
  });
};

export const saveMarks = (data) => (dispatch) => {
  const { marks, studyPhaseBranch, examTitle, examBranch } = data;

  let loading = {
    add: true,
  };
  dispatch(setLoading(loading));
  dispatch({
    type: "apiCall",
    payload: {
      url: `/marks/${examTitle}/${examBranch}/${studyPhaseBranch}`,
      data: { data: marks },
      method: "post",
      onSuccess: "marks/addSuccess",
      onError: "marks/addError",
    },
  });
};
