import React, { useImperativeHandle, forwardRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  Avatar,
  CardMedia,
  Divider,
  CardContent,
  Typography,
  IconButton,
  Box,
  useMediaQuery,
  Card,
  Grid,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import useHost from "../utils/useHost";
import { Close } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cover: {
    height: 200,
    objectFit: "cover",
  },

  profileContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    top: 200,
    position: "absolute",
    flex: 1,
    left: 0,
    right: 0,
    height: 200,
  },
  media: {
    width: 100,
    height: 100,
  },
  title: { marginTop: 8 },
  margin: {
    margin: theme.spacing(1),
  },
}));
const TeacherPreview = forwardRef((props, ref) => {
  const classes = useStyles();
  const theme = useTheme();

  const { URL } = useHost();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({});
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    show(data) {
      setData(data);
      setOpen(true);
    },
  }));
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        fullWidth
        onClose={handleClose}
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogActions>
          <div style={{ flexGrow: 1 }}>
            <IconButton onClick={handleClose} color="primary">
              <Close />
            </IconButton>
          </div>
        </DialogActions>
        <DialogContent>
          <DialogContentText ref={descriptionElementRef} tabIndex={-1}>
            <CardMedia
              className={classes.cover}
              image={`${URL}/${data.avatar}`}
            />

            <Box elevation={0} className={classes.profileContainer}>
              <Avatar className={classes.media} src={`${URL}/${data.avatar}`} />
              <Typography className={classes.title}>{data.name}</Typography>
            </Box>
            <div style={{ height: 150 }}></div>
            <Card elevation={0}>
              <CardContent>
                <Grid container>
                  <Typography style={{ flexGrow: 1 }} gutterBottom variant="h5">
                    Phone
                  </Typography>
                </Grid>

                <Typography gutterBottom variant="body1">
                  {data.phone}
                </Typography>
              </CardContent>
            </Card>
            <Divider />
            {data.teach?.map((item) => (
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader id="nested-list-subheader">
                    {item.book.name}
                  </ListSubheader>
                }
                className={classes.root}
              >
                {item.studyPhaseBranch.map((studyPhaseBranch) => (
                  <ListItem>
                    <ListItemText
                      secondary={`${studyPhaseBranch.studyPhase.name} ${studyPhaseBranch.name}`}
                    />
                  </ListItem>
                ))}
              </List>
            ))}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
});

export default TeacherPreview;
