import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const slice = createSlice({
  name: "alerts",
  initialState: {
    list: [],
  },
  reducers: {
    setLoading: (sample, action) => {
      sample.loading = { ...sample.loading, ...action.payload };
    },

    addSuccess: (alerts, action) => {
      alerts.list.unshift(action.payload);
    },
    removeSuccess: (alerts, action) => {
      alerts.list = alerts.list.filter(
        (item) => item._id !== action.payload._id
      );
    },
  },
});

export default slice.reducer;
export const { addSuccess, removeSuccess } = slice.actions;
export const alertError = (msg) => (dispatch) => {
  let _id = uuidv4();
  dispatch(
    addSuccess({
      _id: _id,
      type: "error",
      msg: msg,
    })
  );

  setTimeout(() => {
    dispatch(removeSuccess({ _id }));
  }, 5000);
};
