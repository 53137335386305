import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "books",
  initialState: {
    list: [],
    fetched: false,
    loading: { get: false },
    errors: [],
  },
  reducers: {
    setLoading: (books, action) => {
      books.loading = { ...books.loading, ...action.payload };
    },
    setErrors: (books, action) => {
      books.errors = {
        ...books.errors,
        ...action.payload,
      };
    },
    getSuccess: (books, action) => {
      books.list = action.payload;
      books.loading.get = false;
      books.fetched = true;
    },

    getError: (books, action) => {
      books.loading.get = false;

      books.errors.get = action.payload.errors[0].msg;
    },
    addSuccess: (books, action) => {},

    addError: (books, action) => {},
    updateSuccess: (books, action) => {},

    updateError: (books, action) => {},
    deleteSuccess: (books, action) => {},

    deleteError: (books, action) => {},
  },
});

export default slice.reducer;
export const { setLoading, setErrors } = slice.actions;
export const getBooks = (data) => (dispatch) => {
  dispatch(setLoading({ get: true }));
  dispatch(setErrors({ get: null }));
  dispatch({
    type: "apiCall",
    payload: {
      url: "/books",
      method: "get",
      onSuccess: "books/getSuccess",
      onError: "books/getError",
    },
  });
};
