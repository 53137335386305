import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Login";
import Root from "./Root";
import Init from "./Init";
import Alerts from "../components/Alerts";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, CssBaseline } from "@material-ui/core";
import { useSelector } from "react-redux";
import NotFound from "./404";
import DateFnsUtils from "@date-io/date-fns";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
export default function App() {
  const { theme } = useSelector((state) => state.user);

  return (
    <ThemeProvider theme={createMuiTheme(theme)}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/init" component={Init} />
            <Route path="/" component={Root} />
            <Route path="*" component={NotFound}></Route>
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
      <Alerts />
    </ThemeProvider>
  );
}
