import React from "react";
import {
  AppBar,
  Breadcrumbs,
  Link,
  Typography,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { drawerWidth } from "../utils/vars";

const useStyles = makeStyles((theme) => ({
  appBar: {
    paddingLeft: 0,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      paddingLeft: drawerWidth,
    },
    flexGrow: 1,
    top: "auto",
    bottom: 0,
  },
  toolbar: {
    minHeight: 35,
  },
}));
function RoutePath({ pathes }) {
  const classes = useStyles();
  return (
    <>
      <Toolbar />
      <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Breadcrumbs aria-label="breadcrumb">
            {pathes.map((item) => {
              if (item.to) {
                return (
                  <Link
                    key={item.title}
                    component={RouterLink}
                    color="inherit"
                    to={item.to}
                  >
                    {item.title}
                  </Link>
                );
              }
              return (
                <Tooltip
                  disableHoverListener={item.tooltip === null}
                  title={item.tooltip ? item.tooltip() : null}
                >
                  <Typography key={item.title} color="textPrimary">
                    {item.title}
                  </Typography>
                </Tooltip>
              );
            })}
          </Breadcrumbs>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default RoutePath;
