import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "details",
  initialState: {
    list: {},
    fetched: {},
    loading: {},
    errors: {},
  },
  reducers: {
    setLoading: (details, action) => {
      details.loading = { ...details.loading, ...action.payload };
    },
    setErrors: (details, action) => {
      details.errors = {
        ...details.errors,
        ...action.payload,
      };
    },
    getSuccess: (details, action) => {
      details.loading[action.payload._id].get = false;
      details.fetched[action.payload._id] = true;

      details.list[action.payload._id] = action.payload.details;
    },

    getError: (details, action) => {},
  },
});

export default slice.reducer;
export const { setLoading, setErrors } = slice.actions;
export const getStudyPhaseBracheDetails = (_id) => (dispatch) => {
  dispatch(setLoading({ [_id]: { get: true } }));
  dispatch(setErrors({ [_id]: { get: null } }));

  dispatch({
    type: "apiCall",
    payload: {
      url: `/details/studyphasebranch/${_id}`,
      data: { _id },
      method: "get",
      onSuccess: "details/getSuccess",
      onError: "details/getError",
    },
  });
};
