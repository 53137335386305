import { makeStyles } from "@material-ui/core";
import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  td: {
    borderWidth: 1,
    borderColor: theme.palette.divider,
  },
  tr: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));
function ExcelPreview({ location }) {
  const classes = useStyles();
  const { data, filename } = location.state;

  return (
    <div>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename={filename}
        sheet="tablexls"
        buttonText="Download as XLS"
      />

      <table id="table-to-xls">
        <tr>
          <th>name</th>
          <th>phone</th>
          <th>address</th>
        </tr>
        {data.map((item) => (
          <tr className={classes.tr}>
            <td className={classes.td}>{item.name}</td>
            <td className={classes.td}>{item.phone}</td>
            <td className={classes.td}>{item.address}</td>
          </tr>
        ))}
      </table>
    </div>
  );
}

export default ExcelPreview;
