import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadUser } from "../store/user";
import { Box, CircularProgress } from "@material-ui/core";
function Init({ history, location }) {
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = "Loading...";
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      dispatch(loadUser(user));

      document.title = user.currentUser.name;
      history.replace(location.state.path);
    } else {
      // dispatch(logout());
      history.replace("/login");
    }
  }, [history, location, dispatch]);
  return (
    <Box flexGrow={1} justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  );
}

export default Init;
