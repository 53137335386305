const useHost = () => {
  const PROTOCOL = "http";
  const HOST = "127.0.0.1";
  const PORT = "5002";
  const CHAT_URL = "https://chat2.alwarith-it.org";
  const URL =
    process.env.NODE_ENV === "production"
      ? "https://admin2.alwarith-it.org"
      : `${PROTOCOL}://${HOST}:${PORT}`;
  return { PROTOCOL, HOST, PORT, URL, CHAT_URL };
};
export default useHost;
